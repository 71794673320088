<template>
    <div>
    <base-header base-title="Caller"></base-header>
    <fullscreen ref="fullscreen" @change="fullscreenChange">
    <div class="kt-content kt-grid__item kt-grid__item--fluid"  id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <option-caller
                v-if="callerMode == callerType.OPTION_CALLER"
                id="option_caller"
                @clickToggle="toggle">
            </option-caller>
            <recent-caller
                v-if="callerMode == callerType.RECENT_CALLER"
                @clickToggle="toggle">
            </recent-caller>
            <unit-caller
                v-if="callerMode == callerType.UNIT_CALLER"
                @clickToggle="toggle">
            </unit-caller>
            <doctor-caller
                v-if="callerMode == callerType.DOCTOR_CALLER"
                @clickToggle="toggle">
            </doctor-caller>
        </div>
      </div>
    </div>
    </fullscreen>
  </div>
</template>

<script>
    import BaseTitle from "./../_base/BaseTitle.vue";
    import BaseHeader from "./../_base/BaseHeader.vue";
    import Fullscreen from "vue-fullscreen/src/component.vue";
    import UnitCaller from "./UnitCallers/UnitCaller.vue";
    import DoctorCaller from "./DoctorCallers/DoctorCaller.vue";
    import { mapState, mapMutations, mapActions } from 'vuex';
    import CallerType from "./../../constants/caller-type-enum";
    import OptionCaller from './OptionCaller';
    import RecentCaller from './RecentCaller';
    import SockJS from "sockjs-client";
    import Stomp from "webstomp-client";
    import { uuid } from 'vue-uuid';
    import { fromEventPattern  } from 'rxjs';
    import { debounceTime } from 'rxjs/operators';
    import CheckPermission from "./../../services/checkPermission.service";
    const checkPermission = new CheckPermission();
    import qz from "qz-tray";
    import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
    const PrinterResourceRepository = RepositoryFactory.get("printerResource");

    const { RSAKey, KJUR, KEYUTIL, stob64, hextorstr } = require('jsrsasign');
    
    export default {
        components: {
            BaseTitle,
            BaseHeader,
            Fullscreen,
            UnitCaller,
            DoctorCaller,
            OptionCaller,
            RecentCaller,
        },
        data() {
          return {
            fullscreen: false,
            callerType: CallerType,
            timeOutTick: '',
            tickAll: [],
            isCheckTelekonsul: false,
            msgData: ''
          }
        },
        beforeRouteLeave(to, from ,next) {
            // console.log(to, from)
            sessionStorage.removeItem('viewCaller')
            next()
        },
        computed: {
            ...mapState('CallerStore', {
                callerMode: state => state.viewCaller,
                historyDokterCaller: state => state.historyDokterCaller,
                historyUnitCaller: state => state.historyUnitCaller,
                queueDevice: state => state.queueDevice,
                userNotification: state => state.userNotification,
                unitQueue: state => state.unitQueue
            }),
            ...mapState("DoctorAppointmentStore", {
                stompClient: state => state.stompClient,
            }),
            ...mapState('DoctorCallerStore', {
                selectedDate: state => state.selectedDate,
                dokter: state => state.dokter,
                jadwal: state => state.jadwal,
            }),
             ...mapState('UnitCallerStore', {
                 unitId: state => state.unit,
             })
        },
        methods: {
            ...mapMutations({
                setViewCaller: 'CallerStore/SET_VIEW_CALLER',
                setSessionId: 'CallerStore/SET_SESSION_ID',
                SET_STOMP_CLIENT: 'DoctorAppointmentStore/SET_STOMP_CLIENT',
                setListPrinter: "CallerStore/SET_LIST_PRINTER",
                setPrintingOption: "CallerStore/SET_PRINTING_OPTION",
                setTenantPrinter: "CallerStore/SET_TENANT_PRINTER"
            }),
            ...mapActions({
                saveUserNotification: 'CallerStore/POST_USER_NOTIFICATION',
                deleteQueueDevice: 'CallerStore/DELETE_QUEUE_DEVICE',
                getDataFromApi: 'UnitCallerStore/GET_DATA_FROM_API',
                getDoctorCallerQueue: 'DoctorCallerStore/GET_DATA_QUEUE',
            }),
            toggle () {
                this.$refs.fullscreen.toggle();
            },
            fullscreenChange (isFullScreen) {
                this.fullscreen = isFullScreen
            },
            async onInit(){
                window.onunload = (e) => {
                   this.deleteQueueDevice();
                }
                try{
                    let viewType = (this.historyUnitCaller.length > 0 || this.historyDokterCaller.length > 0) ? CallerType.RECENT_CALLER : CallerType.OPTION_CALLER;
                    let sessionId = uuid.v4();
                    if(sessionStorage.getItem('sessionId') != undefined){
                        sessionId = sessionStorage.getItem('sessionId');
                    }
                    // resolve bug ganti unit caller error
                    if (this.userNotification == null) {
                        this.saveUserNotification({notificationId: sessionId});
                    }
                    this.setViewCaller(viewType);
                    this.setSessionId(sessionId);
                    // this.initPrinter()
                } catch(err){
                    console.log(err);
                }
            },
            connect(){

                let base = process.env.VUE_APP_WEB_SOCKET;
                let url = `${base}/?access_token=${localStorage.tokens}`
                var socket = new SockJS(url);

                this.SET_STOMP_CLIENT(Stomp.over(socket))
                // to turn the console log off
                this.stompClient.debug = () => {};

                this.stompClient.connect(
                    {},
                    frame => {
                    this.stompClient.subscribe("/topic/webbo", tick => {
                    let viewCaller = sessionStorage.getItem('viewCaller')
                    if(viewCaller) {
                        let msg = JSON.parse(tick.body)
                        var event = new CustomEvent("basket");
                        let queueId = msg.entityData.queueUnit ?
                                        msg.entityData.queueUnit.id :
                                        msg.entityData.queueUnits && msg.entityData.queueUnits.jadwal ? 
                                        msg.entityData.queueUnits.jadwal.id : 
                                        msg.entityData.queueUnits && msg.entityData.queueUnits[0] ? 
                                        msg.entityData.queueUnits[0].id :
                                        'J0'

                        if(viewCaller == 'DOCTOR_CALLER') {
                            clearTimeout(this.timeOutTick)
                            let check = this.tickAll.includes(JSON.stringify(msg))
                            this.tickAll.push(JSON.stringify(msg))
                            this.timeOutTick = setTimeout(()=> {
                                this.tickAll = []
                            }, 1000)
                            if(!check && msg.entityType == 'com.mandayahospital.gateway.external.domain.Queue') {
                                const jadwalId = msg.entityData.queueUnit ? msg.entityData.queueUnit.jadwal.id : ''
                                if(this.jadwal && this.jadwal.id === jadwalId) {
                                    this.isCheckTelekonsul = true
                                    // tunggu 5 detik untuk menutup cek telekonsul
                                    setTimeout(()=> {
                                        this.isCheckTelekonsul = false
                                    }, 5000)
                                    toastr.clear()
                                    if(msg.entityOperation == 'PERSIST') {
                                        toastr.info(`Ada Penambahan Antrian Doctor Caller`)
                                    } else {
                                        toastr.info(`Ada Perubahan Antrian Doctor Caller`)
                                    }
                                    this.msgData = msg.entityOperation == 'PERSIST' ? 'Penambahan' : 'Perubahan'
                                    document.dispatchEvent(event);
                                }
                            } else if (this.isCheckTelekonsul && msg.entityType == 'com.mandayahospital.microservice.telemedicine.domain.Teleconsult') {
                                this.getDoctorCallerQueue()
                            } else if (!check && msg.entityType == 'com.mandayahospital.gateway.external.domain.Reservasi') {
                                toastr.clear()
                                if(msg.entityOperation == 'UPDATE') {
                                    document.dispatchEvent(event);
                                }
                            }
                        } else if( viewCaller == 'UNIT_CALLER') {
                            if(msg.entityType == 'com.mandayahospital.gateway.external.domain.Queue') {
                                if(queueId == `U${this.unitId}`) {
                                    toastr.clear()
                                    if(msg.entityOperation == 'PERSIST') {
                                        toastr.info(`Ada Penambahan Unit Caller`)
                                    } else {
                                        toastr.info(`Ada Perubahan Unit Caller`)
                                    }
                                    this.msgData = msg.entityOperation == 'PERSIST' ? 'Penambahan' : 'Perubahan'
                                    document.dispatchEvent(event);
                                }
                            }
                        }
                    }

                    });
                    },
                    error => {
                        this.disconnect()
                        setTimeout(() => {
                            this.connect();
                        }, 500)
                    // console.log('STOMP Closed',error);
                    }
                );
            },
            basketball(){
                function addClickHandler(handler) {
                    document.addEventListener('basket', handler);
                }

                function removeClickHandler(handler) {
                    document.removeEventListener('basket', handler);
                }
                const clicks = fromEventPattern(
                    addClickHandler,
                    removeClickHandler
                );
                // debounce with rxjs
                const result = clicks.pipe(debounceTime(1000));
                result.subscribe(x => {
                        if(sessionStorage.getItem('viewCaller') !== 'RECENT_CALLER'){
                             let msg = {}
                            if(sessionStorage.getItem('viewCaller') =='UNIT_CALLER'){
                            this.getDataFromApi(sessionStorage.getItem('selectedUnit'))
                                var isActive = window.document.hasFocus()
                                msg = {
                                    body: `Info! \n${this.msgData} di Unit ${this.unitQueue}`
                                }
                                if (!isActive) this.showNotif('Unit Caller', msg)
                            } else {
                                this.getDoctorCallerQueue()
                                var isActive = window.document.hasFocus()
                                msg = {
                                    body: `Info! \n${this.msgData} di Antrian  ${this.dokter.nama}`
                                }
                                if (!isActive) this.showNotif('Doctor Caller', msg)
                            }
                             this.msgData = ''
                        }
                });
            },
            showNotif(title, msg){
               
                if (!("Notification" in window)) {
                    alert("This browser does not support desktop notification");
                }

                else if (Notification.permission === "granted") {

                    var notification = new Notification(title, msg);
                    notification.onclick = function () { 
                        window.parent.parent.focus();
                    }
                }

                else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === "granted") {
                            var notification = new Notification(title, msg);
                        }
                    });
                }

                if(notification !== undefined && notification !== null){
                    // console.log('masuk');
                    notification.onclick = function(event) {
                    window.focus();
                    }
                }else{
                    // console.log('sini');
                    toastr.clear()
                    toastr.error('please allow notification', '', {preventDuplicates: true});
                    // console.log('please allow notification');
                }
            },
            disconnect() {
                sessionStorage.removeItem('viewCaller')
                if (this.stompClient && this.stompClient.status === 'CONNECTED') {
                    this.stompClient.disconnect();
                    // console.log('disconnected');
                }
            },
            checkPermission: function(method, menuShow) {
                const validPermission = checkPermission.isCanAccess(method);
                return validPermission;
            },
            async getServerList(){
                const { data } = await PrinterResourceRepository.getBackOfficeEnv()
                this.setTenantPrinter(data.printingService.tenantPrinter)
            
            },
        },
        created () {
            this.onInit();
            this.connect();
            this.basketball();
            
        },
        mounted(){
            this.getServerList()
            if(!localStorage.defaultPrinter) {
                this.setPrintingOption('DIALOG')
            }
        },
        async destroyed(){
            this.disconnect()
            if (qz.websocket.isActive()) {
                await qz.websocket.disconnect();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
